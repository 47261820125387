
/* eslint-disable eqeqeq */
import { defineComponent } from 'vue'
import moment from 'moment'
import { v4 as UUID } from 'uuid'
import { createNamespacedHelpers } from 'vuex'
import { IChatStoreState } from '@/interfaces'
import { ChatMessageType, IChatGroupModel, IMessageModel } from '@sultan/shared'
import SecondaryButton from '@/components/buttons/SecondaryButton.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import { uploadDocument, chatGroupMessageAttachmentsFolder } from '@/utilities'

const chatHelper = createNamespacedHelpers('chat')
const authHelper = createNamespacedHelpers('auth')

export default defineComponent({
  name: 'ChatPage',

  components: { SecondaryButton, PrimaryButton },

  data () {
    return {
      moment,
      uploadingFile: undefined as File | undefined,
      showChatDetails: false,
      showDropdownAttachmentSelection: false,
      message: '',
      newFile: undefined,
      fileFormatsImages: ['.jpg', '.jpeg', '.png'],
      fileFormats: ['.xlsx', '.pdf', '.csv'],
      paginationCompleted: false,
      isLoading: false
    }
  },

  computed: {
    ...authHelper.mapState(['authUser']),
    ...chatHelper.mapGetters(['messages']),
    ...chatHelper.mapState({
      newMessages (state: IChatStoreState) { return state.newMessages },
      paginatedMessages (state: IChatStoreState) { return state.paginatedMessages },
      messagesUsers (state: IChatStoreState) { return state.messagesUsers },
      chat (state: IChatStoreState) {
        return state.chatGroups.find(chat => chat.id === (this as any).$route.params.id)
      }
    }),
    isUserAChatParticipant () {
      console.debug('ChatPage - isUserAChatParticipant')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const chat: IChatGroupModel = this.chat as any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const user = this.authUser as any

      console.debug('ChatPage - isUserAChatParticipant', { chat, user })
      if (chat == undefined || user == undefined) { return false }

      return chat.memberUserIds.includes(user.uid)
    },
    loadedFileName: function () {
      // eslint-disable-next-line eqeqeq
      return (this as any).uploadingFile == undefined ? '' : ((this as any).uploadingFile as any).name
    },
    mimeTypeSlag () {
      if (this.uploadingFile == undefined) { return '' }
      switch ((this.uploadingFile as any).type) {
        case 'image/jpg':
        case 'image/jpeg': return 'JPG'
        case 'application/pdf': return 'PDF'
        case 'image/png': return 'PNG'
        default: return 'FILE'
      }
    },
    sendIsDisabled () {
      return (this as any).uploadingFile == undefined && ((this as any).message == undefined || (this as any).message.trim().length < 1)
    }
  },

  methods: {
    ...chatHelper.mapActions(['bindChatGroupMessagesRef', 'unbindChatGroupMessagesRef', 'paginateChatGroupMessages', 'fetchChatGroupUsers', 'joinChatGroup', 'sendMessage']),

    isMessageCreator (message: IMessageModel) {
      const user = this.authUser as any
      if (user == undefined) { return false }

      return message.createdByUserId === user.uid
    },
    showChatDetailsOnClick () {
      this.showChatDetails = !this.showChatDetails
    },
    showAttachmentsOptionOnClick () {
      this.showDropdownAttachmentSelection = !this.showDropdownAttachmentSelection
    },
    onSelectImage () {
      (this.$refs.fileInputImage as any).click()
      this.showDropdownAttachmentSelection = false
    },
    onSelectFile () {
      (this.$refs.fileInput as any).click()
      this.showDropdownAttachmentSelection = false
    },
    getFileImage (file: any) {
      this.showDropdownAttachmentSelection = false
      console.log('getFileImage', { file })
      // create a new FileReader to read this image and convert to base64 format
      const reader = new FileReader()
      // Define a callback function to run, when FileReader finishes its job
      reader.onload = (e: any) => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData
        this.uploadingFile = file
        this.newFile = e.target.result
      }
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsDataURL(file)
    },

    async onSendMessage () {
      console.debug('ChatPage - onSendMessage')
      if (this.chat == undefined || this.sendIsDisabled) { return }
      this.isLoading = true

      let attachment: string | undefined
      let attachmentMimeType: string | undefined
      let type: ChatMessageType = ChatMessageType.TEXT
      if (this.uploadingFile != undefined) {
        try {
          attachment = await uploadDocument(chatGroupMessageAttachmentsFolder(this.chat.id), this.uploadingFile, { id: UUID() })
          console.debug('ChatPage - onSendMessage', { attachment, type: this.uploadingFile.type })
          if (this.uploadingFile.type === 'image/jpeg' || this.uploadingFile.type === 'image/png' || this.uploadingFile.type === 'image/gif' || this.uploadingFile.type === 'image/jpg') {
            type = ChatMessageType.IMAGE
          } else {
            type = ChatMessageType.DOCUMENT
          }
          attachmentMimeType = this.uploadingFile.type
        } catch (error) {
          console.error('error uploading the file', { error })
        }
      }

      const result = await this.sendMessage({ chatGroupId: this.chat.id, text: this.message, attachmentUrl: attachment, type, attachmentMimeType: attachmentMimeType })
      if (result) {
        this.message = ''
        this.uploadingFile = undefined
        this.newFile = undefined
      }

      this.isLoading = false
    },
    async onJoinChat () {
      console.debug('ChatPage - onJoinChat')
      if (this.chat == undefined) { return }
      this.isLoading = true

      await this.joinChatGroup({ chatGroupId: this.chat.id })
      this.isLoading = false
    },
    onFileInputChange (event: any) {
      console.log('onFileInputChange', { image: (event as any).target.files[0] })
      // Reference to the DOM input element
      const input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        this.getFileImage(input.files[0])
      }
    },
    async paginateMessages () {
      console.debug('ChatPage - paginateMessages')
      this.isLoading = true
      const { success, complete } = await this.paginateChatGroupMessages({
        chatGroupId: this.$route.params.id
      })

      if (complete) {
        this.paginationCompleted = true
      }
      this.isLoading = false
    },
    getUserDisplayName (userId: string) {
      if (this.messagesUsers == undefined) { return '' }
      const user = this.messagesUsers.find(u => u.id === userId)

      return user == undefined ? '' : `${user.name} ${user.surname}`
    }
  },

  async beforeUnmount () {
    await this.unbindChatGroupMessagesRef()
  },

  watch: {
    messages (newValue: IMessageModel[], oldValue: IMessageModel[]) {
      if (oldValue.length === 0 && newValue.length > 0) {
        console.debug('watch messages - first scroll')
        this.$nextTick(() => {
          const element: HTMLElement = this.$refs.messagesContainer as HTMLElement
          if (element) {
            element.scrollTop = element.scrollHeight
          }
        })
      } else if (oldValue.length > 0) {
        this.$nextTick(() => {
          console.debug('watch messages - other scroll')
          const element: HTMLElement = this.$refs[`message#${oldValue[0].id}`] as HTMLElement
          console.debug(element)
          if (element) {
            element.scrollTop = element.scrollHeight
          }
        })
      }
    },
    newMessages (msgs: IMessageModel[]) {
      this.$nextTick(() => {
        console.debug('watch newMessages - scroll')
        const element: HTMLElement = this.$refs.messagesContainer as HTMLElement
        if (element) {
          element.scrollTop = element.scrollHeight
          // window.scrollTo(0, element.scrollHeight)
        }
      })
    },
    '$route.params.id': {
      handler: async function (id) {
        this.paginationCompleted = false
        await this.unbindChatGroupMessagesRef()
        await this.bindChatGroupMessagesRef({ chatGroupId: id })
        await this.fetchChatGroupUsers({ chatGroupId: id })
        const { success, complete } = await this.paginateChatGroupMessages({ chatGroupId: this.$route.params.id, forceReload: true })
        if (complete) {
          this.paginationCompleted = true
        }
      },
      deep: true,
      immediate: true
    }
  }
})
